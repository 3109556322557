import { useContext } from "react";
import { AppContext } from "../App";
import Course from "./Course";
import Label from "./Label";
import CloseIcon from "../icons/CloseIcon";

function Menu() {
	const { setMenuVisible, courses } = useContext(AppContext);

	return (
		<div id="menu">
			<div className="menu-header">
				<button onClick={() => setMenuVisible(false)}>
					<CloseIcon></CloseIcon>
				</button>
			</div>
			<div className="scroll-content">
				<div className="menu-categories">
					<h5 className="page-title">
						<Label number={11} />
					</h5>
					{courses.map((course, i) => (
						<Course course={course} key={i} />
					))}
				</div>
				<div className="powered">
					<a href="https://www.yellgo.it" target="_blank" rel="noreferrer">
						Powered by YellGo
					</a>
				</div>
			</div>
		</div>
	);
}

export default Menu;
