import React, { useContext } from "react";
import { AppContext } from "../App";
import LangSelector from "./LangSelector";
import HamburgerIcon from "../icons/HamburgerIcon";
import SearchIcon from "../icons/SearchIcon";

function Header() {
	const {
		setMenuVisible,
		list,
		shop,
		settings,
		setCategory,
		setSearchVisible,
		getTranslatedString,
	} = useContext(AppContext);

	return (
		<>
			<div className="header">
				<div className="left">
					{shop && list && settings.menu.categories_menu_type == 1 && (
						<button className="button" onClick={() => setMenuVisible(true)}>
							<HamburgerIcon></HamburgerIcon>
						</button>
					)}
					{shop && list && (
						<button className="button" onClick={() => setSearchVisible(true)}>
							<SearchIcon></SearchIcon>
						</button>
					)}
				</div>
				<button className="logo" onClick={() => setCategory(false)}>
					{settings.menu.logo && <img src={settings.menu.logo} />}
				</button>
				<div className="right">
					<LangSelector />
				</div>
			</div>
			{list.description && list.description != "" && (
				<div className="subheader">{getTranslatedString(list, "description")}</div>
			)}
		</>
	);
}

export default Header;
